<template>
  <v-container fluid class="px-10">
    <v-row>
      <v-col>
        <div class="d-flex">
          <h1 class="p-2 ml-4">Negocios Borradores</h1>
          <v-select
            label="Campaña"
            item-text="name"
            item-value="id"
            dense
            outlined
            clearable
            type="text"
            class="ml-4 mt-3"
            :items="abstractCampaignOptions"
            v-model="abstractCampaignIdFilter"
          ></v-select>
          <v-autocomplete
            label="Establecimiento"
            class="ml-4 mt-3"
            outlined
            dense
            v-model.number="newDraftEngagement.establishmentId"
            :items="establishments"
            item-text="name"
            item-value="id"
            @change="setEstablishmentQuery"
            :clearable="true"
          >
          </v-autocomplete>
        </div>
        <div class="d-flex ml-4 mr-4">
          <v-text-field
            class="mr-2"
            v-model="newDraftEngagement.draftName"
            type="text"
            dense
            outlined
            label="Nombre del Borrador"
          ></v-text-field>
          <v-text-field
            class="mr-2"
            v-model.number="newDraftEngagement.physicalHectares"
            type="text"
            dense
            outlined
            label="Hectáreas físicas a asignar"
          ></v-text-field>
          <v-select
            label="Cultivo Pago Arrendamientos"
            class="mr-2"
            outlined
            dense
            readonly
            filled
            v-model="newDraftEngagement.rentQqActivity"
            :items="activities"
            item-text="name"
            item-value="id"
          >
          </v-select>
          <v-text-field
            label="(0-100)% PVDT"
            dense
            outlined
            type="number"
            append-icon="mdi-percent-outline"
            class="mr-2"
            v-model.number="newDraftEngagement.variableParticipation"
          ></v-text-field>
          <v-text-field
            label="Fecha de PVDT"
            dense
            outlined
            type="date"
            class="mr-2"
            v-model="newDraftEngagement.variableParticipationDate"
          ></v-text-field>

          <v-text-field
            class="mr-2"
            v-model.number="newDraftEngagement.rentQqHa"
            type="number"
            dense
            outlined
            label="Alquiler (Qq/Ha)"
          ></v-text-field>

          <v-text-field
            class="mr-2"
            v-model.number="newDraftEngagement.rentQqActivityPrice"
            type="number"
            dense
            outlined
            label="Precio en adelanto"
          ></v-text-field>

          <v-btn
            color="green white--text"
            class="ml-1"
            @click="saveNewDraftEngagement"
            depressed
          >
            <v-icon> mdi-floppy </v-icon></v-btn
          >
        </div>
        <div class="d-flex">
          <v-btn
            small
            v-if="!isDraftEngagementCompareActive"
            color="blue white--text ml-4 mt-3 p-2"
            dark
            elevation="0"
            @click.stop="setDraftEngagementCompareActive"
          >
            <span class="pr-1">Comparar</span>
            <v-icon> mdi-swap-horizontal-bold </v-icon>
          </v-btn>
          <v-btn
            small
            elevation="0"
            v-if="isDraftEngagementCompareActive"
            color="red white--text ml-4 mt-3 p-2"
            dark
            @click.stop="unSetDraftEngagementCompareActive"
          >
            <span class="pr-1">Cancelar</span>
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col align="end">
        <v-data-table
          v-if="isDraftEngagementCompareActive"
          class="draft-engagement-btn"
          dense
          :headers="vDataTableHeaders"
          :items="draftEngagementsToCompare"
          show-select
          item-key="draftEngagementId"
          hide-default-footer
          v-model="selected"
          ><template v-slot:[`header.data-table-select`]></template
        ></v-data-table>
        <v-btn
          small
          elevation="0"
          v-if="isDraftEngagementCompareActive"
          color="blue white--text ml-4 my-3 p-2"
          :class="{ grey: draftEngagementsToCompare.length < 2 }"
          @click.stop="redirectToCompareDraftEngagements"
        >
          <span class="pr-1">Ir a Comparar</span>
          <v-icon> mdi-swap-horizontal-bold </v-icon>
        </v-btn>
        <v-data-table
          v-if="!isDraftEngagementsEmpty"
          class="draft-engagement-btn"
          dense
          :headers="vDataTableHeaders"
          :items="draftEngagementItems"
          item-key="draftEngagementId"
          :show-select="
            isDraftEngagementCompareActive &&
            draftEngagementsToCompare.length < 2
          "
          v-model="selected"
          :items-per-page="15"
          @click:row="redirectToSingleDraftEngagement"
          ><template v-slot:[`header.data-table-select`]></template
        ></v-data-table>
        <v-data-table
          v-else
          class="btn"
          dense
          :headers="vDataTableHeaders"
          :items-per-page="15"
        ></v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
.draft-engagement-btn:hover {
  cursor: pointer;
}
</style>
<script>

import { mapGetters } from "vuex";
import { refreshToken } from "../managrx/AutAndAuthServices";
import { DRAFT_CONTRACTS, Soja1ActivityId } from "../managrx/constants";
import { postRequest } from "../managrx/serverCall";
export default {
  name: "DraftEngagements",
  props: {
    /* Prop from router */
    establishmentId: Number,
    abstractCampaignId: Number,
  },
  computed: {
    ...mapGetters([
      "abstractCampaigns",
      "draftEngagements",
      "establishments",
      "activities",
      "isDraftEngagementCompareActive",
      "draftEngagementsToCompare",
    ]),
    abstractCampaignOptions() {
      return [
        { id: 0, name: "Sin campaña asociada" },
        ...this.abstractCampaigns,
      ];
    },
    vDataTableHeaders() {
      return [
        { text: "Campaña asociada", value: "campaignName", class: "mx-10" },
        { text: "Nombre del Borrador", value: "draftName" },
        {
          text: "Region",
          value: "region",
        },
        {
          text: "Establecimiento",
          value: "establishmentName",
        },
        {
          text: "Has. Físicas",
          value: "totalHectares",
        },
        {
          text: "Proporción Ha. usadas",
          value: "usedhectaresRatio",
        },
        {
          text: "Creado",
          value: "createdAt",
        },
        {
          text: "Arrendamientos qq/ha",
          value: "rentQqHa",
        },
        {
          text: "Precio en adelanto Arrendamientos",
          value: "rentQqActivityPrice",
        },
      ];
    },
    isDraftEngagementsEmpty() {
      return this.draftEngagements.items.length === 0;
    },
    draftEngagementItems() {
      if (
        !this.abstractCampaignIdFilter &&
        this.abstractCampaignIdFilter !== 0
      ) {
        return this.draftEngagements.items.map((d) => {
          return {
            ...d,
            campaignName: d.abstractCampaignId
              ? `${d.campaignName}`
              : "Sin campaña asociada",
            createdAt: new Date(d.createdAt)
              .toLocaleString("en-US", {
                timeZone: "America/Argentina/Buenos_Aires",
              })
              .split(",")[0],
          };
        });
      } else {
        let abstractCampaignIdFilter = this.abstractCampaignIdFilter
          ? this.abstractCampaignIdFilter
          : null;

        return this.draftEngagements.items
          .filter((el) => el.abstractCampaignId === abstractCampaignIdFilter)
          .map((d) => {
            return {
              ...d,
              campaignName: d.abstractCampaignId
                ? `${d.campaignName}`
                : "Sin campaña asociada",
              createdAt: new Date(d.createdAt)
                .toLocaleString("en-US", {
                  timeZone: "America/Argentina/Buenos_Aires",
                })
                .split(",")[0],
            };
          });
      }
    },
  },

  async mounted() {
    document.title = "Negocios Borradores";
    this.$store.dispatch("clearDraftEngagements");
    this.$store.dispatch("getAbstractCampaigns");
    await this.$store.dispatch("getEstablishments");
    if (this.abstractCampaignId || this.abstractCampaignId == 0) {
      this.abstractCampaignIdFilter = parseInt(this.abstractCampaignId);
    }
    this.$store.dispatch("getActivities");

    if (this.establishmentId) {
      this.newDraftEngagement.establishmentId = this.establishmentId;
      this.$store.dispatch("getDraftEngagements", this.establishmentId);
      this.newDraftEngagement.physicalHectares = this.establishments?.find(
        (el) => el.id == this.establishmentId
      )?.hectares;
    } else {
      this.$store.dispatch("getDraftEngagements");
    }
    this.selected = this.draftEngagementsToCompare;
  },
  methods: {
    setDraftEngagementCompareActive() {
      this.$store.dispatch("setDraftEngagementCompareActive");
    },
    unSetDraftEngagementCompareActive() {
      this.$store.dispatch("setDraftEngagementsToCompare", []);
      this.selected = [];
      this.$store.dispatch("unSetDraftEngagementCompareActive");
    },
    redirectToCompareDraftEngagements() {
      if (this.draftEngagementsToCompare.length == 2) {
        let id1 = this.draftEngagementsToCompare[0]?.draftEngagementId;
        let id2 = this.draftEngagementsToCompare[1]?.draftEngagementId;
        this.$router.push({
          name: "DraftEngagementCompare",
          params: { id1: id1, id2: id2 },
        });
      }
    },
    redirectToSingleDraftEngagement(event) {
      if (!this.isDraftEngagementCompareActive) {
        this.$router.push({
          name: "SingleDraftEngagement",
          params: { id: event.draftEngagementId },
        });
      }
    },
    setEstablishmentQuery() {
      let establishmentId = this.newDraftEngagement.establishmentId;
      if (establishmentId) {
        let query = {};
        if (this.abstractCampaignIdFilter !== null) {
          query = {
            establishmentId: establishmentId,
            abstractCampaignId: this.abstractCampaignIdFilter,
          };
        } else {
          query = {
            establishmentId: establishmentId,
          };
        }
        this.$store.dispatch("getDraftEngagements", establishmentId);
        this.$router.push({
          name: "DraftEngagements",
          query,
        });
      } else {
        this.$router.push({
          name: "DraftEngagements",
        });
      }
    },
    async saveNewDraftEngagement() {
      this.newDraftEngagement.variableParticipationDate = new Date(
        this.newDraftEngagement?.variableParticipationDate
      ).toISOString();
      // (0-100) to (0-1)
      this.newDraftEngagement.variableParticipation =
        this.newDraftEngagement?.variableParticipation / 100;

      let payload = this.newDraftEngagement;

      let response = await postRequest(`/draft_engagement`, payload);

      if (!response.success) {
        this.$store.dispatch("setNewNotification", response);
      } else {
        let tokenResponse = await refreshToken();
        if (tokenResponse.success) {
          let draftEngagementId = response.data;
          this.$router.push(`/draft-engagement/${draftEngagementId}`);
        } else {
          let notif = {
            success: false,
            data: {
              type: "alert",
              text: "Error al refrecar el Token, porfavor sal e inicia sesión nuevamente",
            },
          };
          this.$store.dispatch("setNewNotification", notif);
        }
      }
    },
  },
  data() {
    return {
      abstractCampaignIdFilter: null,
      draftContracts: DRAFT_CONTRACTS,
      newDraftEngagement: {
        variableParticipation: 15,
        physicalHectares: null,
        rentQqActivity: Soja1ActivityId,
        materializationStatus: 4,
        draftName: "Borrador" + Math.floor(Date.now() / 10000).toString(),
        variableParticipationDate: new Date().toISOString().split("T")[0],
        contractType: null,
      },
      selected: [],
    };
  },
  watch: {
    selected(val, oldVal) {
      if (val.length > 2) {
        this.$nextTick(() => {
          this.selected = oldVal;
        });
      } else {
        this.$nextTick(() => {
          this.$store.dispatch("setDraftEngagementsToCompare", val);
        });
      }
    },
  },
};
</script>
